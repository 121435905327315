<template>
  <vx-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Code Agence" v-model="Code" class="w-full" name="Code" />
        <span class="text-danger"></span>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Nom officine" v-model="NomOffic" class="w-full" name="Nom_offic" />
        <span class="text-danger"></span>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Regime officine"  v-model="RegimeOffic" class="w-full" name="Regime_offic" />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <vs-input label="Opticien"  v-model="Opticien" class="w-full" name="Opticien"/>
        <span class="text-danger"></span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input label="Adresse" v-model="adresse" class="w-full mt-4" name="adresse"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-select v-model="Pays" autocomplete class="w-full select-large mt-4" label="Pays">
          <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in paysData" class="w-full" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-select v-model="Ville" autocomplete class="w-full select-large mt-4" label="Ville">
          <vs-select-item :key="index" :value="item.libelleVille" :text="item.libelleVille" v-for="(item,index) in villeData" class="w-full" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-select v-model="Quartier" autocomplete class="w-full select-large mt-4" label="Quartier">
          <vs-select-item :key="index" :value="item.libelleQtier" :text="item.libelleQtier" v-for="(item,index) in quartierData" class="w-full" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input label="Localite" v-model="Localite" class="w-full mt-4" name="localite"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input label="Fax" v-model="Fax" class="w-full mt-4" name="tel"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input label="Tel" v-model="Tel" class="w-full mt-4" name="tel"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input label="Cel" v-model="Cel" class="w-full mt-4" name="tel"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-select v-model="joursOuvrable" autocomplete multiple  class="selectExample w-full mt-4" label="Jours ouvrable">
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in Jours" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-10">
        <div class="flex items-center mb-4">
          <span class="mr-4">Officine active:</span>
          <vs-switch class="mr-4" v-model="OfficineActive" />
          <span class="mr-4">Officine principale:</span>
          <vs-switch class="mr-4" v-model="OfficinePrincipale" />
        </div>
      </div>
    </div>
    <div class="vx-row mt-base">
      <div class="vx-col text-right w-full ml-auto">
        <vs-button class="mr-3 mb-2" @click="updateOfficine" >Modifier</vs-button>
        <vs-button color="warning" type="border" class="mb-2" @click="getOfficines">Annuler</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  data () {
    return {
      Code: '',
      NomOffic: '',
      RegimeOffic: '',
      Opticien: '',
      OfficinePrincipale: false,
      OfficineActive: true,
      adresse: '',
      Pays: 'CIV',
      Ville: '',
      Quartier: '',
      Localite: '',
      Tel: '',
      Cel: '',
      Fax: '',
      Web: '',
      joursOuvrable: [],
      Jours: [
        { text: 'Lundi', value: 1 },
        { text: 'Mardi', value: 2 },
        { text: 'Mercredi', value: 3 },
        { text: 'Jeudi', value: 4 },
        { text: 'Vendredi', value: 5 },
        { text: 'Samedi', value: 6 },
        { text: 'Dimanche', value: 0 }
      ],
      officineData: null
    }
  },
  computed: {
    getOfficineById () {
      return this.$store.getters['officine/getOfficineById'](this.$route.params.getOfficineById)
    },
    paysData () {
      return this.$store.state.pays.pays
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    }
  },
  methods: {

    updateOfficine () {

      const payload = {
        Code: this.Code,
        NomOffic: this.NomOffic,
        RegimeOffic: this.RegimeOffic,
        Opticien: this.Opticien,
        adresse: this.adresse,
        Pays: this.Pays,
        Ville: this.Ville,
        Quartier: this.Quartier,
        Localite: this.Localite,
        Tel: this.Tel,
        Cel: this.Cel,
        Fax: this.Fax,
        OfficinePrincipale: this.OfficinePrincipale,
        OfficineActive: this.OfficineActive,
        joursOuvrable: this.joursOuvrable,
        id: this.$route.params.officineId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('officine/updateOfficine', payload)
        .then((resp) => {
          console.log(resp)
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    initData (data) {
      this.Code = data.Code
      this.NomOffic = data.NomOffic
      this.RegimeOffic = data.RegimeOffic
      this.Opticien = data.Opticien
      this.OfficinePrincipale = data.OfficinePrincipale
      this.OfficineActive = data.OfficineActive
      this.adresse = data.Adresse.adresse
      this.Pays = data.Adresse.Pays
      this.Ville = data.Adresse.Ville
      this.Quartier = data.Adresse.Quartier
      this.Localite = data.Adresse.Localite
      this.Tel = data.Adresse.Tel
      this.Cel = data.Adresse.Cel
      this.Fax = data.Adresse.Fax
      this.joursOuvrable = data.joursOuvrable

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then((resp) => {
          this.officineData = resp.data.find((o) => o._id === this.$route.params.officineId)
          this.initData(this.officineData)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => {
        })
        .catch((err) => {
          console.log(err)
        })

    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    this.getOfficines()
    if (!(this.$store.state.pays.pays.length > 0)) this.getPays()
    if (!(this.$store.state.pays.villes.length > 0)) this.getVilles()
    if (!(this.$store.state.pays.quartiers.length > 0)) this.getQuartiers()
  },
  components: {

  }
}
</script>
